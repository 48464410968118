<template>
  <v-container>
    <v-row align="center" justify="center">
      <h1 class="blue--text text--darken-2 font-italic">PRODUCTS</h1>
    </v-row>
    <v-row>
      <v-col
        v-for="n in 6"
        :key="n"
        class="d-flex child-flex"
        cols="6"
        md="4"
      >
        <v-card hover :to="`/productdetail/${products[n-1].id}`">
          <v-img :src="require(`@/assets/img/${products[n-1].img}`)" class="grey lighten-2" />
          <v-card-text class="text-center subtitle-1 py-2">Shenmamid&reg; {{ products[n-1].name }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        color="blue-grey lighten-3"
        class="white--text my-2 mr-4"
        to="/products/0"
      >
        More
        <v-icon
          right
          dark
        >
          mdi-page-next-outline
        </v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import productlist from '@/assets/json/productlist.json'

export default {
  data: () => ({
    products: productlist.products
  })
}
</script>
