<template>
  <div>
    <v-carousel
      cycle
      interval="3000"
      :height="height"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item
        v-for="(banner, i) in banners"
        :key="i"
        :src="banner.src"
      >
      </v-carousel-item>
    </v-carousel>

    <v-row dense class="blue-grey lighten-5 py-2">
      <v-col
        cols="6"
        sm="4"
        md="2"
      >
        <v-card
          class="mx-auto"
          max-width="180"
          hover
          to="/applications/1"
        >
          <div class="py-4 d-flex flex-column justify-space-between align-center">
            <v-img :src="imgs1" width="60px"></v-img>
          </div>
          <v-card-text class="text-center title pa-0">电子电器</v-card-text>
          <v-card-text class="text-center pt-0 pb-4">Electronic Products</v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
      >
        <v-card
          class="mx-auto"
          max-width="180"
          hover
          to="/applications/2"
        >
          <div class="py-4 d-flex flex-column justify-space-between align-center">
            <v-img :src="imgs2" width="60px"></v-img>
          </div>
          <v-card-text class="text-center title pa-0">生活用品</v-card-text>
          <v-card-text class="text-center pt-0 pb-4">Daily Necessities</v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
      >
        <v-card
          class="mx-auto"
          max-width="180"
          hover
          to="/applications/3"
        >
          <div class="py-4 d-flex flex-column justify-space-between align-center">
            <v-img :src="imgs3" width="60px"></v-img>
          </div>
          <v-card-text class="text-center title pa-0">建筑材料</v-card-text>
          <v-card-text class="text-center pt-0 pb-4">Building Materials</v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
      >
        <v-card
          class="mx-auto"
          max-width="180"
          hover
          to="/applications/4"
        >
          <div class="py-4 d-flex flex-column justify-space-between align-center">
            <v-img :src="imgs4" width="60px"></v-img>
          </div>
          <v-card-text class="text-center title pa-0">机械配件</v-card-text>
          <v-card-text class="text-center pt-0 pb-4">Machinery Parts</v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
      >
        <v-card
          class="mx-auto"
          max-width="180"
          hover
          to="/applications/5"
        >
          <div class="py-4 d-flex flex-column justify-space-between align-center">
            <v-img :src="imgs5" width="60px"></v-img>
          </div>
          <v-card-text class="text-center title pa-0">工业制品</v-card-text>
          <v-card-text class="text-center pt-0 pb-4">Industrial Products</v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
      >
        <v-card
          class="mx-auto"
          max-width="180"
          hover
          to="/applications/6"
        >
          <div class="py-4 d-flex flex-column justify-space-between align-center">
            <v-img :src="imgs6" width="60px"></v-img>
          </div>
          <v-card-text class="text-center title pa-0">汽车部件</v-card-text>
          <v-card-text class="text-center pt-0 pb-4">Automotive Products</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <ProductsList></ProductsList>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-img src="../assets/company.jpg"></v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <h1 class="blue--text text--darken-2 font-italic">ABOUT US</h1>
        <p>Our Company Our Company Our Company Our Company Our Company Our Company Our Company Our Company Our Company Our Company</p>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        color="blue-grey lighten-3"
        class="white--text my-2 mr-4"
        to="/about/2"
      >
        More
        <v-icon
          right
          dark
        >
          mdi-page-next-outline
        </v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
  import ProductsList from './ProductsList'

  export default {
    components: {
      ProductsList,
    },

    data () {
      return {
        imgs1: require('../assets/cpu.svg'),
        imgs2: require('../assets/coffee.svg'),
        imgs3: require('../assets/home.svg'),
        imgs4: require('../assets/settings.svg'),
        imgs5: require('../assets/tool.svg'),
        imgs6: require('../assets/truck.svg'),
        banners: [
          {
            src: require('../assets/banner1.jpg'),
            text1: "做深产业 提高质量 开拓市场 科技创新",
            text2: "Deep Industry | Improving Quality | Opening Up Markets | Technological Innovation",
          },
          {
            src: require('../assets/banner2.jpg'),
            text1: "科技创新是企业发展的源动力",
            text2: "Technological Innovation Is The Source Of EnterPrise Development",
          },
          {
            src: require('../assets/banner3.jpg'),
            text1: "提供高性能尼龙材料解决方案",
            text2: "Provide High-performance Nylon Material Solutions",
          },
          {
            src: require('../assets/banner4.jpg'),
            text1: "材料创新生活",
            text2: "Material Innovation Life",
          },
        ],
      }
    },

    computed: {
      height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 200
          case 'sm': return 300
          case 'md': return 400
          case 'lg': return 500
          case 'xl': return 600
          default: return "auto"
        }
      },
    },
  }
</script>
